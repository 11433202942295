/* SlideShow.css */
.textBannerCenter{
  position: absolute;
  top: 30%;
  right: 130px;
  max-width: 45%;
  padding: 3px 1px 3px 3px;
  background-color: #3333334b;
  font-size: 1.6rem;
  color: #fff;
  padding: 1px 2px;
  text-align:start;

}
.contDivPencepaly{
  position: relative;
  cursor: pointer;
  width: 100%;
}
#contentImg{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.imgCardSlideNovoy{
  width: 100%;
}

.slide-show {
    text-align: center;
    position: relative;
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
.contDivPencepal{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
  }
  .imgCardSlideNovo{
    height: 400px;
    width: 100%;
    object-fit: cover;
  }
  .butGoSlide{
    display: block;
    position: absolute;
    right: 20px;
    top: 45%;
    padding: 10px 10px;
    border: none;
    border-radius: 0px 5px 5px 0px;
    /* box-shadow: 0.5px 0.5px 3px 0.5px var(--verdeC); */
    background-color: var(--verde);
    color: #fff;
    font-weight: 600;
    cursor: pointer;
  }
  .butGoSlideb{
    display: block;
    position: absolute;
    left: 20px;
    top: 45%;
    padding: 10px 10px;
    border: none;
    border-radius: 5px 0px 0px 5px;
    /* box-shadow: 0.5px 0.5px 3px 0.5px var(--verdeC); */
    background-color: var(--verde);
    color: #fff;
    font-weight: 600;
    cursor: pointer;
  }
  
  .slide-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .slide {
    display: none;
    width: 100%;
    transition: opacity 0.5s;
  }
  
  .slide.active {
    display: block;
    opacity: 1;
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
  
  .prev,
  .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 10px 20px;
    border: none;
    outline: none;
    transition: background-color 0.3s;
  }
  
  .prev:hover,
  .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .slide-indicators {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .indicator {
    width: 12px;
    height: 12px;
    background-color: #ccc;
    border-radius: 50%;
    margin: 0 8px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .indicator.active-indicator {
    background-color: #333;
  }

  .carregarText{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 750px){
    .textBannerCenter{
      font-size: 1.2rem;
    }
  }
  @media (max-width: 650px){
    .textBannerCenter{
      font-size: 1rem;
    }
  }
  @media (max-width: 500px){
    .textBannerCenter{
      display: none;
    }
  }
  