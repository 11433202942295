.Projeto{
    width: 100%;
    margin: 0px;
    padding: 0px;
}
.fullContentFast{
    width: 100%;
    height: 400px;
    /* background-image: url('../../../public/dg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */
}
.colorFastContent{
    width: 100%;
    height: 400px;
    /* background: linear-gradient(to right, rgba(0, 0, 0, 0.653), var(--verdeE)); */
}
.oitentaFastContent{
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.centerFastContent{
    font-size: 2rem;
    color: #fff;
}
.paragradoFastContent{
    color: #fff;
}
.fullProjectsSecund{
    width: 100%;
    padding: 50px 0px 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #dddddd55;
}
.oitentaProjectsSecund{
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items:flex-start;
    flex-wrap: wrap;
}
.oitentaProjectsSecundNew{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items:flex-start;
    flex-wrap: wrap;
    gap: 3%;
}
.imgProjectSecund{
    height: 280px;
    width: 280px;
    border-radius: 5px;
}
.cardProjectsSecund{
    margin: 20px 0px;
    width: 280px;
    padding: 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #ddd;
    border-radius: 5px;
}
.HeiddenProjectsSecund{
    width: 100%;
    height: 150px;
    background-color: #fff;
}
.textProjectsSecund{
    width: 100%;
    display: flex;align-items: center;
    justify-content: center;
    flex-direction: column;
}
.TitleProjectsSecund{
    color: var(--verdeE);
    font-size: 1.2rem;
    font-weight: 600;
}
.descriptionProjectsSecund{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px 0px;
}
.arrowSizee{
    font-size: 2rem;
    color: var(--verdeE);
    margin: 20px 0px;
}
#buttomCardSecund{
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.org{
    font-size: 1.3rem;
    color: var(--verdeE);
    width: 100%;
    text-align: center;
}
.ObjCard{
    width: 100%;
    padding: 15px 0px;
    text-align: justify;
}
.textBackTrie{
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.elementCardTrie{
    width:20%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.sizeTrie{
    font-size: 6rem;
    color: var(--amarelo);
    padding: 5px 0px;
}
.numberTrie{
    color: var(--amarelo);
    font-size: 2rem;
    font-weight: 600;
    padding: 5px 0px;
}
.descTrie{
    color: var(--amarelo);
    font-size: 0.8rem;
    padding: 10px 0px;
}
.risco{
    width: 100px;
    height: 3px;
    background-color: var(--amarelo);
}
@media (max-width: 1340px){
    .oitentaProjectsSecund{
        width: 90%;
    }
}
@media (max-width: 1277px){
    .oitentaProjectsSecundNew{
        width: 95%;
    }
}
@media (max-width: 1100px){
    .oitentaProjectsSecund{
        width: 95%;
    }
}
@media (max-width: 1050px){
    .oitentaProjectsSecund{
        width: 80%;
    }
}
@media (max-width: 820px){
    .oitentaProjectsSecund{
        width: 90%;
    }
}
@media (max-width: 720px){
    .textBackTrie{
        width: 90%;
    }
    .sizeTrie{
        font-size: 5rem;
        color: var(--amarelo);
        padding: 5px 0px;
    }
    .numberTrie{
        color: var(--amarelo);
        font-size: 1.8rem;
        font-weight: 600;
        padding: 5px 0px;
    }
    .descTrie{
        color: var(--amarelo);
        font-size: 0.7rem;
        padding: 10px 0px;
        width: 80%;
        text-align: center;
    }
    .risco{
        width: 90px;
        height: 3px;
        background-color: var(--amarelo);
    }
    .cardProjectsSecund{
        width: 240px;
    }
    .imgProjectSecund{
        height: 240px;
        width: 240px;
    }
    .paragradoFastContent{
        width: 80%;
        text-align: center;
    }
}
@media (max-width: 700px){
    .centerFastContent{
        text-align: center;
    }
}
@media (max-width: 560px){
    .cardProjectsSecund{
        width: 200px;
    }
    .imgProjectSecund{
        height: 200px;
        width: 200px;
    }
    .paragradoFastContent{
        width: 95%;
        text-align: center;
    }
    .fullProjectsSecund{
        padding: 20px 0px 20px 0px;
    }
}
@media (max-width: 540px){
    .cardProjectsSecund{
        width: 300px;
    }
    .imgProjectSecund{
        height: 300px;
        width: 300px;
    }
    .oitentaProjectsSecund{
        width: 80%;
        display: flex;
        justify-content: center;
        align-items:flex-start;
        flex-wrap: wrap;
    }

}
@media (max-width: 480px){
    .textBackTrie{
        width: 90%;
        justify-content: space-between;
    }
    .sizeTrie{
        font-size: 3.5rem;
        color: var(--amarelo);
        padding: 5px 0px;
    }
    .numberTrie{
        color: var(--amarelo);
        font-size: 1.5rem;
        font-weight: 600;
        padding: 5px 0px;
    }
    .descTrie{
        color: var(--amarelo);
        font-size: 0.7rem;
        padding: 10px 0px;
        width: 80%;
        text-align: center;
    }
    .risco{
        width: 85px;
        height: 3px;
        background-color: var(--amarelo);
    }
}
@media (max-width: 400px){
    .textBackTrie{
        width: 90%;
        justify-content: space-between;
    }
    .sizeTrie{
        font-size: 3rem;
        color: var(--amarelo);
        padding: 5px 0px;
    }
    .numberTrie{
        color: var(--amarelo);
        font-size: 1.3rem;
        font-weight: 600;
        padding: 5px 0px;
    }
    .descTrie{
        color: var(--amarelo);
        font-size: 0.7rem;
        padding: 10px 0px;
        width: 80%;
        text-align: center;
    }
    .risco{
        width: 65px;
        height: 3px;
        background-color: var(--amarelo);
    }
}