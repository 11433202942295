.Humburguer{
    width: 100%;
}

  
  .topnav {
    overflow: hidden;
    background-color: var(--verde);
    position: relative;
  }
  
  .topnav #myLinks {
    display: none;
  }
  
  .topnav a {
    color: white;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    display: block;
  }
  
  .topnav div.icon {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .active {
    background-image: linear-gradient(to left,var(--verde), var(--verdeC));
    color: white;
  }
  .icon{
    width: 50px;
    height: 40px;
  }
  .iall{
    color: #fff;
    font-size: 1.7rem;
    display: block;
    margin-top: 15px;
    cursor: pointer;
  }
  .ImgHumburguer{
    height: 1.7rem;
    border-radius: 50%;
  }
  @media (min-width: 650px){
    .Humburguer{
        display: none;
    }
}