.dfTexxt{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px;
}
.adddivInputPost{
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.inpAdds{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.adddInputHead{
    width: 80%;
    height: 40px;
    margin: 0px 10px;
    border-radius: 5px;
    font-size: 1.2rem;
}
.btnAddBut{
    width: 50px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor:pointer;
}
.addmais{
    font-size: 1.4rem;
}
.formPostDataMembro{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.nomeDoUsuario{
    width: 100%;
    height: 40px;
    border-radius: 5px;
    margin: 10px 0px;
    font-size: 1.2rem;
}
.bannerEditform{
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 5px;
    background-color: var(--amarelo);
    font-size: 1.7rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(55, 55, 22);
    margin: 10px 0px;
}
.bannerEditformVerde{
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 5px;
    background-color: var(--verde);
    font-size: 1.7rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(35, 64, 90);
    margin: 10px 0px;
}
.marginTopParaOCard{
    margin:10px 0px;
}
.margLefIconEd{
    margin: 0px 10px;
    color: var(--amarelo);
    font-size: 1.5rem;
    cursor: pointer;
}
.divIconss{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.newcolortoIcon{
    margin: 20px 0px;
    font-size: 1.5rem;
    color: var(--vermelho);
    cursor: pointer;
}
.paragrafoNossa{
    width: 100%;
    text-align: justify;
}
.WBuuton{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.butbtnNeww{
    width: 100px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: none;
    box-shadow: 1px 1px 1px 1px var(--verdeE);
    background-color: var(--verdeE);
    color: #fff;
    font-weight: 600;
    cursor: pointer;
}
.cardBuutt{
    width: 90px;
    height: 40px;
    padding: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #fff;
    background-color: var(--amarelo);
    border-radius: 5px;
    box-shadow: 1px 1px 2px 2px rgb(199, 199, 14);
    font-weight: 600;
    cursor: pointer;
}
.redIconDelit{
    font-size: 1.5rem;
    color: var(--vermelho);
    cursor: pointer;
}
.descCArdMembroRedIcon{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
}
.centerOitenta{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

}