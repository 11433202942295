.ContainerLogin{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 70px 0px;
}
.imageLogg{
    width: 70px;
    height: 70px;
    border-radius: 50%;
}
.contentLogin{
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.imgLogoLogin{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.imgLogoLogin{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.7rem;
    font-weight: 600;
    padding: 10px 0px;
    font-family: 'Roboto', sans-serif;
}
.contentFormLogin{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.inputTextNameUserAdmA{
    width: 100%;
    height: 40px;
    border: none;
    box-shadow: 1px 1px 1px 1px #ddd;
    margin: 10px 0px;
    border-radius: 5px;
    font-size: 1.1rem;
}
.buttDivParaPoderAlinhar{
    width: 100%;
    border: none;
    padding: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.paddinBuutt{
    width: 200px;
    height: 40px;
    border: none;
    border-radius: 5px;
    box-shadow: 1px 1px 1px 1px #ddd;
    font-size: 1.5rem;
    cursor: pointer;
}
.newTextBocx{
    height: 1px;
    opacity: 0;
}
.modalConfirm{
    width: 35%;
    background-color: #f6f6f69c;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 0px 0px  0px;
    box-shadow: 1px 1px 6px 2px #ddd;
    border-radius: 10px;
    position: relative;

}
.buttonTextConfirmModal{
    width: 100%;
    height: 40px;
    background-color: var(--verde);
    color: #fff;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 0px 0px 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.textModalconfirm{
    font-size: 1.3rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
    color: var(--verdeE);
}
.inpConfirmSenhaModal{
    height: 45px;
    width: 90%;
    border-radius: 10px;
    border: none;
    box-shadow: 1px 1px 3px 2px #ddd;
    margin: 35px 0px 35px 0px;
}
.classX{
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    color: #7d7b7b;
    font-size: 1.4rem;
}
.textModalconfirmtxt{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 0px;
    color: #666;
}
.bannnerConfirm{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(244, 161, 161);
    border: none;
    border-top: 1px solid red;
    border-bottom: 1px solid red;
}
.liBaner{
    color: red;
}