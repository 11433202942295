.Header{
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 60px;
    /* background-color: #ddd; */
    /* background-image: linear-gradient(to left,#999, #ddd); */
    background-image: linear-gradient(to left,var(--verde), var(--verdeC));
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.allcontent{
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.flag{
    width: 15%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0px;
}
.flagItem{
    margin: 0px 10px;
}
.itemImg{
    height: 40px;
    transform: scale(0.90);
}
.itemImg:hover{
    cursor: pointer;
    transform: scale(1);
    transition: 0.3s;
}
.menu{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.pesquisa{
    display: flex;
    align-items: center;
}
.inputSearch{
    width: 17rem;
    height: 45px;
    border-radius: 30px;
    padding-inline: 10px;
    border: none;
    box-shadow: 1px 1px 1px 1px #dddddda0;
    font-weight: 500;
    color: #666;
    font-size: 0.8rem;
}
.iconPosition{
    margin-left: -30px;
    font-size: 1.3rem;
    color: var(--verdeE);
    background-color: #fff;
}
.iconsSocial{
    width: 23%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.iconsoc{
    font-size: 1.7rem;
    cursor: pointer;
}
.iconsoc:hover{
    color: var(--verdeE);
}
.doar{
    width: 17%;
    height: 40px;
    display: flex;align-items: center;
    justify-content: center;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: 600;
    border: none;
    background-color: var(--verdeE);
    box-shadow: 1px 1px 1px 1px #005555a9;
    margin: 0px 10px;
    /* color: #fff; */
}
.doar:hover{
    background-color: var(--verdeC);
    box-shadow: 1px 1px 1px 1px var(--verdeC);
    font-weight: 600;
    transition: 0.3s;
}
@media (max-width: 1300px){
    .menu{
        width: 60%;
    }
}
@media (max-width: 1100px){
    .menu{
        width: 70%;
    }
}
@media (max-width: 950px){
    .iconsSocial{
        display: none;
    }
    .menu{
        width: 70%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        
    }
}
@media (max-width: 750px){
    .doar{
        display: none;
    }
}
@media (max-width: 650px){
    .Header{
        display: none;
    }
}
@media (max-width: 580px){
    .allcontent{
        width: 93%;
    }
}