.Colaboradores{
    width: 100%;
    margin: 0px;
    padding: 0px;
}
.pesquisadores{
    width: 100%;
    margin: 0px;
    padding: 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dddddd90;
}
.pesquisawhite{
    width: 100%;
    margin: 0px;
    padding: 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #fff; */
    background-color: var(--verdeC);
}
.oitentaPesquisadores{
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.TitlePesquisadores{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--verdeE);
    font-size: 3rem;
    padding: 30px 0px;
}
.cardColaboradores{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0px;
}
.cardColaboradoresWhite{
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0px;
}
.imgColaPes{
    height: 300px;
    border-radius: 10px;
}
.descColaboradorPes{
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.nomeColaPes{
    font-size: 3rem;
    color: var(--verdeE);
    padding: 10px 0px;
}
.descColaPes{
    font-size: 1.2rem;
    color: #666;
}
@media (max-width: 1100px){
    .oitentaPesquisadores{
        width: 85%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
}
@media (max-width: 920px){
    .oitentaPesquisadores{
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
    .imgColaPes{
        height: 250px;
    }
    .nomeColaPes{
        font-size: 2rem;
    }
    .TitlePesquisadores{
        font-size: 1.6rem;
    }
}
@media (max-width: 720px){
    .imgColaPes{
        height: 200px;
    }
    .nomeColaPes{
        font-size: 1.5rem;
    }
    .descColaPes{
        font-size: 1rem;
    }
}
@media (max-width: 600px){
    .imgColaPes{
        height: 160px;
    }
    .nomeColaPes{
        font-size: 1.2rem;
    }
    .descColaPes{
        font-size: 0.8rem;
    }
}
@media (max-width: 480px){
    .imgColaPes{
        height: 250px;
    }
    .nomeColaPes{
        font-size: 1.5rem;
    }
    .descColaPes{
        font-size: 1rem;
    }
    .cardColaboradores{
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: flex-start;
        padding: 30px 0px;
    }
    .cardColaboradoresWhite{
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: flex-start;
        padding: 30px 0px;
    }
    .descColaPes{
        width: 100%;
        text-align: center;
    }
    .descColaboradorPes{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
}
@media (max-width: 480px){
    .imgColaPes{
        height: 200px;
    }
}
@media (max-width: 320px){
    .imgColaPes{
        height: 150px;
    }
}